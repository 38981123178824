import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { ticket } from "../../../../Context/validation";
import { ticketAdd, ticketList, ticketReply } from "../../../../Context/api";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import {
    statusCodes,
    projectId,
    commonMessage,
} from "../../../../Constants/generalConfig";
import { environment } from "../../../../Context/environment";
import { Buffer } from "buffer";

const useTicket = () => {
    const [ticketShow, setTicketShow] = useState(false);
    const [ticketDetailShow, setTicketDetailShow] = useState(false);
    const [imageFile, setImageFile] = useState([]);
    const [listData, setListData] = useState([]);
    const [ticketId, setTicketId] = useState("");
    const [spinner, setSpinner] = useState(false);
    const [attachments, setAttachments] = useState([]);
    const [replyMsg, setReplyMsg] = useState([]);
    const [count, setCount] = useState(1);
    const [page, setPage] = useState(1);
    const [status,setStatus]=useState('')
    const PER_PAGE = 10;
    const navigate = useNavigate();
    const userData = JSON.parse(localStorage.getItem("userData"));
    const [showMore, setShowMore] = useState(false);
    const [filter, setFilter] = useState({
        list: 'MYTICKETS',
        search: ''
    })


    useEffect(() => {
        if (userData == null) {
            navigate("/");
        } else {
            list();
        }
    }, [page]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFilter((prevProps) => ({
            ...prevProps,
            [name]: value
        }));
    };
    let encodeRequest = (input) => {
        let salt = "123";
        const buffer = Buffer.from(salt, "utf-8");
        const base64EncodedSalt = buffer.toString("base64");
        let encodePayload = Buffer.from(JSON.stringify(input), "utf-8").toString(
            "base64"
        );
        let encodeHash = Buffer.from("####", "utf-8").toString("base64");
        let delimiter = "@@@";

        let base64Payload = encodePayload + delimiter + encodeHash + delimiter + base64EncodedSalt;
        return base64Payload;
    };
    let decodeOutput = (result, delimiter = "@@@") => {
        const components = result.split(delimiter);
        return Buffer.from(components[0], "base64").toString("utf-8");
    };
    const addTicket = useFormik({
        initialValues: {
            subject: "",
            description: "",
            priority: ""
        },
        validationSchema: ticket,
        onSubmit: (values) => {
            let date = moment().format();
            const formData = new FormData();
            formData.append("project_id", projectId);
            formData.append("reportDate", date);
            formData.append("source", "ONLINE");
            formData.append("subject", values.subject.trim());
            formData.append("description", values.description.trim());
            formData.append("priority", values.priority);
            formData.append("parent_id", userData?.roleTitle=='SERVICE_PROVIDER'?userData?.userId:userData?.createdbyId);
            formData.append("reportedBy", JSON.stringify({
                id: userData?.userId,
                firstName: userData?.name,
                lastName: "",
                email: userData?.email,
            })
            );
            formData.append("type", "TICKET");
            delete formData.files;
            let formDataObject = {};
            for (const [key, value] of formData.entries()) {
                formDataObject[key] = value;
            }

            const formDataObjectRR = JSON.parse(formDataObject.reportedBy);
            formDataObject.reportedBy = formDataObjectRR


            const newFormData = new FormData();
            newFormData.append("input", encodeRequest(formDataObject));
            if (imageFile.length) {
                imageFile.map((item) => { newFormData.append("files", item.file) });
            }
            else newFormData.delete("files");
            // if (imageFile.length) newFormData.append("files", imageFile.map((item=>item.file)));
            // if (imageFile.length) newFormData.append("files", imageFile.map((item=>item.file)));
            // else newFormData.delete("files");

            // console.log(formDataObject, 'rrrrrrrrrr')
            setSpinner(true);
            axios.post(ticketAdd(), newFormData, { headers: { Authorization: `Bearer ${userData?.token}` }, }).then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    toast.success(resp.data.message);
                    addTicket.resetForm();
                    list();
                    setTicketShow(false);
                    setSpinner(false);
                } else {
                    toast.error(resp.data.message);
                    setSpinner(false);
                }
            })
                .catch(() => {
                    toast.error(commonMessage.networkError);
                    setSpinner(false);
                });
        },
    });
    const list = () => {
        const data = {
            count: PER_PAGE,
            page: page,
            project_id: projectId,
            reportedBy_id: userData?.userId,
            tab:filter.list,
            search:filter.search,
            parent_id:userData?.roleTitle==='SERVICE_PROVIDER'?userData?.userId:userData?.createdbyId
        };
        setSpinner(true);
        let delimiter = ""
        let newData = { input: encodeRequest(data) }
        // let result = decodeOutput(encodeRequest(data), delimiter = "@@@")
        // console.log(result, 'ooooooo')
        axios.post(ticketList(), newData).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setSpinner(false);
                setCount(resp.data.totalCount);
                let rr = decodeOutput(resp.data.data, delimiter = "@@@")
                setListData(JSON.parse(rr))

            } else {
                setListData([]);
                setSpinner(false);
            }
        })
            .catch(() => {
                toast.error(commonMessage.networkError);
                setSpinner(false);
            });

        // const response = await axios.post(ticketList(), newData)
        // if (response.data.code != statusCodes.ok) {
        //     throw new Error(response?.data?.message || commonMessage.networkError)
        // }
        // return response.data
    };
    const ticketView = (id) => {
        let delimiter = ""
        axios.get(environment.ticketBaseUrl + `/api/external/ticket/${id}`)
            .then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    let rr = decodeOutput(resp.data.data, delimiter = "@@@")
                    ticketDetail?.setFieldValue("email", JSON.parse(rr)?.reportedByData?.email);
                    ticketDetail?.setFieldValue("description", JSON.parse(rr)?.description);
                    ticketDetail?.setFieldValue("subject", JSON.parse(rr)?.subject);
                    ticketDetail?.setFieldValue("priority", JSON.parse(rr)?.priority);
                    // console.log(JSON.parse(rr), 'AAAAAA')
                    setAttachments(JSON.parse(rr)?.attachments);
                    setReplyMsg(JSON.parse(rr)?.replies);
                    setStatus(JSON.parse(rr)?.status)
                } else {
                    setAttachments([])
                    setReplyMsg([])
                }
            });
    };
    const ticketDetail = useFormik({
        initialValues: {
            email: "",
            subject: "",
            description: "",
            reply: "",
            priority: ""
        },
        onSubmit: (values) => {
            if (!values.reply) {
                toast.warn("Please enter message");
                return;
            }
            const data = {
                ticket_id: ticketId?._id,
                reply: values.reply.trim(),
                reportedBy_id: userData?.userId,
            };
            let newData = { input: encodeRequest(data) }
            setSpinner(true);
            axios.post(ticketReply(), { ...newData }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    toast.success(resp.data.message);
                    ticketDetail.setFieldValue("reply", "");
                    setSpinner(false);
                    replyMsg.push({
                        createdAt: moment().format(),
                        reply: values.reply,
                        createdByData: { firstName: userData?.name }
                    });
                } else {
                    toast.error(resp.data.message);
                    setSpinner(false);
                }
            });
        },
    });
    const getFileExtensionFromUrl = (url) => {
        const segments = url.split("/");
        const filename = segments[segments?.length - 1];
        const parts = filename.split(".");
        const fileExtension = parts[parts?.length - 1];
        return fileExtension;
    };
    const handleFileChange = (event) => {

        const data = Array.from(event.target.files);
        const temp = [...imageFile]
        for (const file of data) {
            if (data.length > 5) return toast.warn("You can select only 5 items.")
            if ((data.length + imageFile.length) > 5) return toast.warn("You can select only 5 items.")
            temp.push({ path: URL.createObjectURL(file), file: file });
        }
        setImageFile(temp)
    };
    const DeleteAttachmentFile = (index) => {
        setImageFile((pre) => {
            let temp = [...pre]
            temp.splice(index, 1)
            return temp
        })
    }
    return {
        ticketView,
        setTicketId,
        navigate,
        setTicketDetailShow,
        setTicketShow,
        setImageFile,
        setPage,
        getFileExtensionFromUrl,
        handleFileChange,
        DeleteAttachmentFile,
        setShowMore,
        handleInputChange,
        list,
        filter,
        showMore,
        PER_PAGE,
        page,
        count,
        spinner,
        listData,
        ticketShow,
        addTicket,
        imageFile,
        ticketDetail,
        attachments,
        replyMsg,
        ticketDetailShow,
        userData,
        status
    };
};

export default useTicket;
