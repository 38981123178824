import React from 'react'
import { Modal } from 'react-bootstrap';
import ValidationMessage from '../../ValidationMessage/validationMessage';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
const CreateTicket = ({ ticketShow, setTicketShow, addTicket, imageFile, handleFileChange, DeleteAttachmentFile }) => {
  console.log(imageFile,'imageFile')
  return (
    <Modal size="lg" centered show={ticketShow} onHide={() => setTicketShow(false)}>
      <div className='m-3 modal-lg'>
        <div style={{ minHeight: '55vh' }}>
          <div className='d-flex justify-content-between text-center'>
            <h5 className='mt-1'>Create Ticket</h5>
            <button className='btn cursor text-danger' onClick={() => { setTicketShow(false); addTicket.resetForm() }}>X</button>
          </div>
          <div style={{ border: '1px solid #b3b3b3', borderRadius: '5px', padding: '10px' }}>
            <div className="row mb-3">
              <div className="col-sm-2">
                <label className="form-label mt-1">Subject <span className='text-danger'>*</span></label>
              </div>
              <div className="col-sm-10">
                <input type="text" className="form-control" placeholder="" name="subject" value={addTicket.values.subject} onChange={addTicket.handleChange} />
                <ValidationMessage formName={addTicket} name={'subject'} />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-2">
                <label className="form-label mt-1">Description <span className='text-danger'>*</span></label>
              </div>
              <div className="col-sm-10">
                <CKEditor
                  editor={ClassicEditor}
                  name='description'
                  onChange={(event, editor) => {
                    const data = editor.getData()
                    addTicket.setFieldValue('description', data)
                  }}
                  value={addTicket.values.description}
                />
                <ValidationMessage formName={addTicket} name={'description'} />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-2">
                <label className="form-label mt-1">Priority <span className='text-danger'></span></label>
              </div>
              <div className="col-sm-10">
                <select className="form-select form-control" aria-label="Default select example" name="priority" value={addTicket.values.priority} onChange={addTicket.handleChange}>
                  <option value='' hidden>Select Priority</option>
                  <option value="LOW">Low</option>
                  <option value="MEDIUM">Medium</option>
                  <option value="HIGH">High</option>
                </select>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-sm-2"></div>
              <div className="col-sm-10">
                <label htmlFor="inputTag">
                  <input id="inputTag" type="file" multiple style={{ display: 'none' }} onChange={event => { handleFileChange(event) }} />
                  <span id="imageName"></span>
                  <p className='navi_clr cursor'>+Attach a file</p>
                </label>
              </div>
              <div className="col-sm-2"></div>
              <div className='col-sm-10 Ticket_Attachment'>
                <div className="row">
                  {
                    imageFile?.map((cur, index) => {
                      let filetype = cur?.file.name.split('.').pop()
                      if (filetype === "jpg" || filetype === "jpeg" || filetype === "png") {
                        return <div className="col-4 mb-3  mx-0 position-relative ">
                          <img src={cur?.path} alt="" />
                          <span onClick={() => DeleteAttachmentFile(index)}>X</span>
                        </div>
                      }
                      if (filetype === "mp4" || filetype === "wmv" || filetype === "mov") {
                        return <div className="col-4 mb-3  mx-0 position-relative">
                          <video src={cur?.path} alt="" autoplay controls> </video>
                          <span onClick={() => DeleteAttachmentFile(index)}>X</span>
                        </div>
                      }
                      if (filetype !== "jpg" || filetype !== "jpeg" || filetype !== "png" || filetype !== "mp4" || filetype !== "wmv" || filetype !== "mov") {
                        return <div className="col-4 mb-3  mx-0 position-relative ">
                          <i className="fa-sharp fa-regular fa-file cursor" style={{ fontSize: '48px', color: 'red' }}></i>
                          <span onClick={() => DeleteAttachmentFile(index)}>X</span>
                        </div>
                      }
                    })
                  }
                </div>
              </div>
            </div>
            <div className='d-flex justify-content-center mt-5 mb-3'>
              <button className='btn ps-5 pe-5 cancel_btn' onClick={() => { setTicketShow(false); addTicket.resetForm() }}>Cancel</button>
              <button className='btn ps-5 pe-5 ms-2 btn_Style' type='submit' onClick={addTicket.handleSubmit}>Submit</button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default CreateTicket